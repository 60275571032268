import { Box } from "@mui/material";
import { ButtonWithLoading } from "components/elements/button/ButtonWithLoading";
import { MyTextField } from "components/elements/form/MyTextField";
import { StatusSelect } from "components/elements/select/StatusSelect";
import { CountryDto } from "dto/static/country.dto";
import { useForm } from "hooks/useForm";
import { useLabel } from "hooks/useLabel";
import React from "react";
import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObject: CountryDto;
  onSubmit: (data: CountryDto) => void;
  loading: boolean;
};
const FormCountry: React.FC<Props> = ({ defaultObject, onSubmit, loading }) => {
  const { LL } = useLabel();

  const [obj, disabled, setObjField, , , , vResults] = useForm<CountryDto>(
    defaultObject,
    RequiredValidator.getValidators(["name", "nameofficial"])
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <MyTextField
            field="name"
            label={LL("field_name")}
            setObjectField={setObjField}
            value={obj.name}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="nameofficial"
            label={LL("field_nameofficial")}
            setObjectField={setObjField}
            value={obj.nameofficial}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="code2"
            label={LL("field_code2")}
            setObjectField={setObjField}
            value={obj.code2}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="code3"
            label={LL("field_code3")}
            setObjectField={setObjField}
            value={obj.code3}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="coden"
            label={LL("field_coden")}
            setObjectField={setObjField}
            value={obj.coden}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="phonecode"
            label={LL("field_phonecode")}
            setObjectField={setObjField}
            value={obj.phonecode}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="tld"
            label={LL("field_tld")}
            setObjectField={setObjField}
            value={obj.tld}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="html_code"
            label={LL("field_html_code")}
            setObjectField={setObjField}
            value={obj.html_code}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="urlprefix"
            label={LL("field_urlprefix")}
            setObjectField={setObjField}
            value={obj.urlprefix}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="order"
            label={LL("field_order")}
            setObjectField={setObjField}
            value={obj.order}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <StatusSelect setObjField={setObjField} value={obj.status} />
        </Box>
        <Box mt={3}>
          <ButtonWithLoading
            type="submit"
            disabled={loading || disabled}
            loading={loading}
          >
            {LL("btn_submit")}
          </ButtonWithLoading>
        </Box>
      </form>
    </Box>
  );
};

export { FormCountry };
