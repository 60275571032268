import React, { useEffect, useState } from "react";
import { HeaderListSpecial } from "components/elements/list/HeaderListSpecial";

import { IPage } from "interfaces/page.interface";

import { CommonTools } from "tools/utils/common.tool";
import { useResource } from "hooks/useResource";
import { useList } from "hooks/useList";
import { RouteDto } from "dto/system/route.dto";
import { AclService } from "services/system/acl.service";
import RequestListDTO from "dto/app/requestlist.dto";
import { Loading } from "components/elements/loading/Loading";
import { Box, IconButton, Stack } from "@mui/material";
import { GroupAclList } from "components/acl/GroupAclList";
import { MenuAcl } from "components/acl/MenuAcl";
import { MyTextField } from "components/elements/form/MyTextField";
import { useLabel } from "hooks/useLabel";
import CloseIcon from "@mui/icons-material/Close";
import { GroupAction } from "components/acl/GroupAction";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";

const service = new AclService();

const SpecialAclRoles: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const { _getListUrl, triggerAclRolesGlobal } = useResource();

  const mainUrl = CommonTools.generateMainUrlSpecial(currentRoute);
  const [parentUrl, setParentUrl] = useState("");
  const [parentType, setParentType] = useState("");
  const [parentId, setParentId] = useState("");
  const [search, setSearch] = useState("");
  const { LL } = useLabel();
  const setObjectField = (field: string, value: any) => {
    setSearch(value);
  };

  const getListFun = (cb: any, cbParams: any, reqList: any) => {
    service.getRoutes(cb, cbParams, reqList);
  };

  const [loading, objects] = useList<RouteDto>(
    getListFun,
    new RequestListDTO([], 1, -1, [
      RequestSortCriteriaDTO.prepareSortCriteria("routegroup", true),
    ]),
    []
  );

  const processParentData = () => {
    if (!currentRoute) return;
    if (!currentRoute._paths) return;
    const path = currentRoute._paths;
    if (path.length < 3) return;
    setParentType(path[0]);
    setParentId(path[1]);
  };

  useEffect(() => {
    if (!currentRoute) return;
    const lu = _getListUrl(
      CommonTools.generateParentMainUrlSpecial(currentRoute)
    );
    setParentUrl(lu);
    processParentData();
  }, [currentRoute]);

  const handleClear = () => {
    setSearch("");
  };

  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (loading) return <Loading />;
  if (!objects) return null;
  if (!parentId) return null;
  const parsedObject = RouteDto.searchGroup(RouteDto.getGroup(objects), search);
  return (
    <React.Fragment>
      <HeaderListSpecial
        mainObject={mainObject}
        mainUrl={mainUrl}
        addUrl={""}
        specialType="aclroles"
        parentUrl={parentUrl}
        parentType={parentType}
        parentId={parentId}
        showAddBtn={false}
      />
      {/* <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          spacing={1}>
          <MyTextField
            label={LL("search")}
            value={search}
            setObjectField={setObjectField}
            field="search"
          />
          <IconButton onClick={handleClear}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Box>
          <GroupAction
            groupName={""}
            idRole={parentId}
            trigger={triggerAclRolesGlobal}
          />
        </Box>
      </Stack> */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "space-between",
          // justifyContent: "space-around",
        }}>
        <Box
          sx={{
            maxWidth: "1200px",
            width: "100%",
            display: "flex",
            flexDirection: { xs: "column-reverse", md: "row" },
            alignItems: "flex-start",
          }}>
          <Box
            sx={{
              width: { xs: "100%", md: "70%" },
              display: "flex",
              flexDirection: "column",
              alignItems: "starts",
              justifyContent: "center",
              // px: 2,
              py: 3,
              maxWidth: "700px",
            }}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                spacing={1}>
                <MyTextField
                  label={LL("search")}
                  value={search}
                  setObjectField={setObjectField}
                  field="search"
                />
                <IconButton onClick={handleClear}>
                  <CloseIcon />
                </IconButton>
              </Stack>
              <Box>
                <GroupAction
                  groupName={""}
                  idRole={parentId}
                  trigger={triggerAclRolesGlobal}
                />
              </Box>
            </Stack>
            <GroupAclList objects={parsedObject} idRole={parentId} />
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "30%" },
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}>
            <Box
              sx={{
                position: { md: "fixed" },
                top: "100px",
                width: { xs: "100%", md: "30%" },
                px: 3,
              }}>
              <MenuAcl objects={parsedObject} />
            </Box>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export { SpecialAclRoles };
