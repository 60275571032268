import SelectOptions from "dto/app/selectoptions.dto";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { CommonTools } from "tools/utils/common.tool";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";

import { FilterTools } from "tools/utils/filter.tool";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";
import { GeneralService } from "services/general.service";
import { FilterService } from "services/product/filter.service";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { FilterType } from "tools/types/filtertype";
import { FilterDto } from "./filter.dto";

const service = new FilterService();
export class FilterDictionarDto implements Idto {
  id?: number | string;
  identifier?: string;
  idfilter?: number;
  order?: number;

  status?: number;
  _idlang?: number | string;
  _name?: string;

  constructor(
    _idlang?: number | string,
    id?: number | string,
    idfilter?: number,
    identifier?: string,
    order?: number,
    status?: number,
    _name?: string
  ) {
    this.id = id || 0;
    this.identifier = identifier || "";
    this.order = order || 0;
    this.status = status || Status.ACTIVE;
    this._idlang = _idlang || 0;
    this._name = _name || "";
    this.idfilter = idfilter || 0;
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "filter",
        value: ["filterObj", "_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "identifier",
        value: ["identifier"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "order",
        value: ["order"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "name",
        value: ["_name"],
      },
    ].concat(CommonTools.displayMedia());
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "id", "#"),
      ComponentCommonTools.columns_StandardColumn(LL, "order"),
      ComponentCommonTools.columns_StandardColumn(LL, "identifier"),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_StandardColumn(LL, "_name"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete", "gallery", "attachment", "video"],
        ["detail", "attachment", "video"],
        cbParent,
        specialType
      ),
    ];
  }

  static async getFilters(): Promise<TbFilterListDTO> {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);

    const data = (await service.getList(
      undefined,
      undefined,
      new RequestListDTO(
        [
          RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
          RequestFilterDTO.prepareFilter("type", [
            FilterType.DICTIONAR.toString(),
          ]),
        ],
        1,
        -1
      )
    )) as ResultListDTO<FilterDto>;
    
    if (data) {
      const objects = data.objects;
      if (objects) {
        t = new TbFilterDTO("idfilter", FilterTools.fiterTypeSelectMultiple);
        t.setValues(FilterDto.parseToSelectOptions(objects));
        obj.addFilter(t);
      }
    }
    return obj;
  }

  static parseToSelectOptions(
    data: Array<FilterDictionarDto>,
    all?: boolean
  ): Array<SelectOptions> {
    if (all === undefined) all = false;
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    if (all) result.push(new SelectOptions(-1, GeneralService.LL("All")));
    data.forEach((element) => {
      result.push(FilterDictionarDto.parseOption(element));
    });
    return result;
  }

  static parseOption = (obj: FilterDictionarDto): SelectOptions => {
    return new SelectOptions(
      CommonTools.processObjectField(obj, ["id"]),
      CommonTools.processObjectField(obj, ["_name"])
    );
  };

  static getSelectedIdFilter = (req: RequestListDTO): string => {
    if (!req) return "";
    if (!req.filters) return "";
    if (!Array.isArray(req.filters)) return "";
    if (!req.filters.length) return "";
    const filter = req.filters.find((x) => x.field === "idfilter");
    if (!filter) return "";
    if (!filter.values) return "";
    if (!Array.isArray(filter.values)) return "";
    if (!filter.values.length || filter.values.length > 1) return "";

    return filter.values[0];
  };
}
