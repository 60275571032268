import { Box, Chip, Typography } from "@mui/material";
import { MyCheckboxField } from "components/elements/form/MyCheckboxField";
import { MyBackdrop } from "components/elements/loading/MyBackdrop";


import ResultObjectDTO from "dto/app/resultobject.dto";
import { RouteDto } from "dto/system/route.dto";
import { AclRolesDto } from "dto/user/aclroles.dto";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import React, { useState } from "react";
import { AclRolesService } from "services/user/aclroles.service";
import { CommonTools } from "tools/utils/common.tool";

type Props = {
  object: AclRolesDto;
  setObject: (value: AclRolesDto) => void;
};

const service = new AclRolesService();

const ActionAclRole: React.FC<Props> = ({ object, setObject }) => {
  const {handleTriggerAclRolesGlobal} = useResource();
  const [obj, , setObjectField] = useForm(object, []);
  const [loading, setLoading] = useState(false);
  const handleSubmit = (field: string, value: any) => {
    setObjectField(field, value);
    obj.value = value;

    setLoading(true);
    if (obj.id) {
      service.update(obj.id.toString(), handleResponse, {}, obj);
    } else {
      service.add(handleResponse, {}, obj);
    }
  };

  const handleResponse = (data: ResultObjectDTO<AclRolesDto>) => {
    if (!data) return;
    const object = data.obj ? data.obj : new AclRolesDto();
    setObject(object);
    setLoading(false);
    handleTriggerAclRolesGlobal();
  };

  return (
    <Box>
      <MyCheckboxField
        field="value"
        checked={CommonTools.processNumberToBoolean(obj.value)}
        isNumber
        label={
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "flex-start",
              m: 1,
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Chip
                label={CommonTools.processObjectField(object, ["routemethod"])}
                color={RouteDto.getRouteMethodColor(
                  CommonTools.processObjectField(object, ["routemethod"])
                )}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography>
                {CommonTools.processObjectField(object, ["routename"])}
              </Typography>
            </Box>
          </Box>
        }
        setObjectField={handleSubmit}
      />
      <MyBackdrop loading={loading} />
    </Box>
  );
};

export { ActionAclRole };
