import { DateTools } from "tools/utils/date.tool";

import { RouteTools } from "tools/utils/route.tool";
import { CommonTools } from "./common.tool";
import { GridRenderCellParams } from "@mui/x-data-grid/models/params/gridCellParams";
import { GridActionsCellItem } from "@mui/x-data-grid";
import ChildCareRoundedIcon from "@mui/icons-material/ChildCareRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CollectionsIcon from "@mui/icons-material/Collections";
import FilePresent from "@mui/icons-material/FilePresent";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import { Link } from "@mui/material";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import { Image } from "components/elements/display/Image";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import QuizIcon from "@mui/icons-material/Quiz";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import FilterListIcon from "@mui/icons-material/FilterList";
import { FilterType } from "tools/types/filtertype";
import { FilterDto } from "dto/product/filter.dto";
import TimelineIcon from "@mui/icons-material/Timeline";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import { Favorite, VerifiedUser } from "@mui/icons-material";

const ACTION_TABLE = "table";
class ComponentCommonTools {
  public static columns_GetCheck = () => {
    return {
      field: "__check__",
      hideable: false,
      sortable: false,
      width: 1,
      align: "center",
      headerAlign: "center",
    };
  };

  public static columns_StandardColumn = (
    LL: any,
    field: string,
    customLabel?: string,
    width?: any,
    sortable?: boolean,
    hideable?: boolean,
    type?: string
  ) => {
    const obj: any = {};
    obj["field"] = field;
    obj["headerName"] = customLabel ? customLabel : LL("headerName_" + field);
    obj["hideable"] = hideable != undefined ? hideable : true;
    obj["sortable"] = sortable != undefined ? sortable : true;
    if (field === "id") width = 60;
    if (width != undefined) obj["width"] = width;
    else obj["flex"] = 1;
    if (type != undefined) obj["type"] = type;

    return obj;
  };

  public static columns_TimestampObjectColumn = (
    LL: any,
    field: string,
    width?: any,
    sortable?: boolean,
    hideable?: boolean,
    type?: string
  ) => {
    const obj: any = {
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.row !== undefined) {
          if (params.row.hasOwnProperty(field)) {
            return DateTools.displayTimestampInHHMM(params.row[field]);
          }
        } else {
          return "";
        }
      },
    };

    obj["field"] = field;
    obj["headerName"] = LL("headerName_" + field);
    obj["hideable"] = hideable != undefined ? hideable : true;
    obj["sortable"] = sortable != undefined ? sortable : true;

    if (width !== undefined) obj["width"] = width;
    else obj["flex"] = 1;
    if (type != undefined) obj["type"] = type;

    return obj;
  };

  public static columns_StandardObjectColumn = (
    LL: any,
    objField: string,
    field: string,
    width?: any,
    sortable?: boolean,
    hideable?: boolean,
    type?: string
  ) => {
    const obj: any = {
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.row !== undefined) {
          if (params.row.hasOwnProperty(objField)) {
            if (params.row[objField].hasOwnProperty(field)) {
              return params.row[objField][field];
            }
          }
        } else {
          return "";
        }
      },
    };

    obj["field"] = objField + field;
    obj["headerName"] = LL("headerName_" + objField + field);
    obj["hideable"] = hideable != undefined ? hideable : true;
    obj["sortable"] = sortable != undefined ? sortable : false;

    if (width !== undefined) obj["width"] = width;
    else obj["flex"] = 1;
    if (type != undefined) obj["type"] = type;

    return obj;
  };

  public static columns_ConstantColumn = (
    LL: any,
    field?: string,
    width?: any,
    sortable?: boolean,
    hideable?: boolean,
    type?: string
  ) => {
    field = field ?? "status";

    const obj: any = {
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.row !== undefined) {
          if (params.row.hasOwnProperty(field + "_name")) {
            return params.row[field + "_name"];
          }
        } else {
          return "";
        }
      },
    };
    // obj["field"] = field + "_name";
    obj["field"] = field;
    obj["headerName"] = LL("headerName_" + field);
    obj["hideable"] = hideable != undefined ? hideable : true;
    obj["sortable"] = sortable != undefined ? sortable : true;
    if (width !== undefined) obj["width"] = width;
    else obj["flex"] = 1;
    if (type != undefined) obj["type"] = type;

    return obj;
  };

  public static columns_ImageColumn = (
    LL: any,
    field?: string,
    width?: any,
    sortable?: boolean,
    hideable?: boolean,
    type?: string,
    imgwidth?: number,
    imgheight?: number
  ) => {
    imgwidth = imgwidth ?? 50;
    imgheight = imgheight ?? 50;

    const obj: any = {
      renderCell: (params: GridRenderCellParams<any>) => {
        field = field ?? "cdnurl";
        if (params.row !== undefined) {
          if (params.row !== undefined && params.row.hasOwnProperty(field)) {
            const fieldValue = params.row[field];

            let displayField: string = "";
            if (fieldValue !== undefined && typeof fieldValue === "string") {
              displayField = fieldValue as string;
            }

            if (displayField) {
              return (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Image
                    url={fieldValue}
                    typeMeasure="pixel"
                    withLinK
                    sizeInPx={{
                      width: imgwidth,
                      height: imgheight,
                    }}
                  />
                </div>
              );
            } else {
              return "";
            }
          }
        } else {
          return "";
        }
      },
    };
    obj["field"] = field + "_name";
    obj["headerName"] = LL("headerName_" + field);
    obj["hideable"] = hideable != undefined ? hideable : true;
    obj["sortable"] = sortable != undefined ? sortable : true;
    if (width !== undefined) obj["width"] = width;
    else obj["flex"] = 1;
    if (type != undefined) obj["type"] = type;

    return obj;
  };

  static processOptionActionColumn = (
    module: string,
    LL: (str: string) => string,
    params: any,
    inmenu: string[] | undefined,
    mainObject: string,
    icon: JSX.Element,
    onClick?: (_obj: any, mainObject: any, str: string) => void
  ) => {
    if (onClick === undefined) onClick = ComponentCommonTools.goToSpecial;
    return (
      <GridActionsCellItem
        icon={icon}
        label={LL("BTLabList_" + module)}
        title={LL("BTTitleList_" + module)}
        onClick={() => {
          if (onClick) onClick(params.row, mainObject, module);
        }}
        showInMenu={
          inmenu !== undefined && inmenu.indexOf(module) !== -1 ? true : false
        }
      />
    );
  };

  public static columns_FileColumn = (
    LL: any,
    field?: string,
    width?: any,
    sortable?: boolean,
    hideable?: boolean,
    type?: string
  ) => {
    const obj: any = {
      renderCell: (params: GridRenderCellParams<any>) => {
        field = field ?? "cdnurl";
        if (params.row !== undefined) {
          if (params.row !== undefined && params.row.hasOwnProperty(field)) {
            const fieldValue = params.row[field];
            let displayField: string = "";
            if (fieldValue !== undefined && typeof fieldValue === "string") {
              displayField = fieldValue as string;
            }

            if (displayField) {
              return (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Link href={displayField} target="_blank" rel="noreferrer">
                    <FileOpenIcon />
                  </Link>
                </div>
              );
            } else {
              return "";
            }
          }
        } else {
          return "";
        }
      },
    };
    obj["field"] = field + "_name";
    obj["headerName"] = LL("headerName_" + field);
    obj["hideable"] = hideable != undefined ? hideable : true;
    obj["sortable"] = sortable != undefined ? sortable : true;
    if (width !== undefined) obj["width"] = width;
    else obj["flex"] = 1;
    if (type != undefined) obj["type"] = type;

    return obj;
  };

  public static columns_UrlColumn = (
    LL: any,
    field?: string,
    width?: any,
    sortable?: boolean,
    hideable?: boolean,
    type?: string
  ) => {
    const obj: any = {
      renderCell: (params: GridRenderCellParams<any>) => {
        field = field ?? "url";
        if (params.row !== undefined) {
          if (params.row !== undefined && params.row.hasOwnProperty(field)) {
            const aUrl = params.row[field];

            if (aUrl) {
              return (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <a href={aUrl} target="_blank" rel="noreferrer">
                    {aUrl}
                  </a>
                </div>
              );
            } else {
              return "";
            }
          }
        } else {
          return "";
        }
      },
    };
    obj["field"] = field + "_name";
    obj["headerName"] = LL("headerName_" + field);
    obj["hideable"] = hideable != undefined ? hideable : true;
    obj["sortable"] = sortable != undefined ? sortable : true;
    if (width !== undefined) obj["width"] = width;
    else obj["flex"] = 1;
    if (type != undefined) obj["type"] = type;

    return obj;
  };

  public static columns_ActionsColumn_IsAvaible = (options: any) => {};

  public static columns_ActionsColumn = (
    LL: any,
    mainObject: any,
    deleteFun?: any,
    options?: string[],
    inmenu?: string[],
    cbParent?: any,
    specialtype?: string
  ) => {
    const num = options ? options.length + 1 : 1;
    const length = 360 / (11 / num);
    const obj: any = {
      field: "actions",
      type: "actions",
      flex: 1,
      minWidth: length,
      align: "center",
      headerAlign: "center",
      getActions: (params: any) => [
        options &&
        options.indexOf("gallery") != -1 &&
        RouteTools.checkRoutePermission(
          RouteTools.getPmHash_List("gallery")
        ) ? (
          <GridActionsCellItem
            icon={<CollectionsIcon />}
            label={LL("BTLabList_gallery")}
            title={LL("BTTitleList_gallery")}
            onClick={() => {
              ComponentCommonTools.goToGallery(params.row, mainObject);
            }}
            showInMenu={
              inmenu != undefined && inmenu.indexOf("gallery") != -1
                ? true
                : false
            }
          />
        ) : (
          <></>
        ),
        options &&
        options.indexOf("attachment") != -1 &&
        RouteTools.checkRoutePermission(
          RouteTools.getPmHash_List("attachment")
        ) ? (
          <GridActionsCellItem
            icon={<FilePresent />}
            label={LL("BTLabList_attachment")}
            title={LL("BTTitleList_attachment")}
            onClick={() => {
              ComponentCommonTools.goToAttachment(params.row, mainObject);
            }}
            showInMenu={
              inmenu != undefined && inmenu.indexOf("attachment") != -1
                ? true
                : false
            }
          />
        ) : (
          <></>
        ),
        options &&
        options.indexOf("video") != -1 &&
        RouteTools.checkRoutePermission(RouteTools.getPmHash_List("video")) ? (
          <GridActionsCellItem
            icon={<OndemandVideoIcon />}
            label={LL("BTLabList_video")}
            title={LL("BTTitleList_video")}
            onClick={() => {
              ComponentCommonTools.goToVideo(params.row, mainObject);
            }}
            showInMenu={
              inmenu != undefined && inmenu.indexOf("video") != -1
                ? true
                : false
            }
          />
        ) : (
          <></>
        ),
        options &&
        options.indexOf("edit") != -1 &&
        RouteTools.checkRoutePermission(
          RouteTools.getPmHash_Update(mainObject)
        ) ? (
          <GridActionsCellItem
            icon={<Edit />}
            label={LL("BTLabList_Edit")}
            title={LL("BTTitleList_Edit")}
            onClick={() => {
              ComponentCommonTools.goToEdit(params.row, mainObject);
            }}
            showInMenu={
              inmenu != undefined && inmenu.indexOf("edit") != -1 ? true : false
            }
          />
        ) : (
          <></>
        ),
        options &&
        options.indexOf("editspecial") != -1 &&
        RouteTools.checkRoutePermission(
          RouteTools.getPmHash_Update(specialtype ?? "")
        ) ? (
          <GridActionsCellItem
            icon={<Edit />}
            label={LL("BTLabList_Edit")}
            title={LL("BTTitleList_Edit")}
            onClick={() => {
              ComponentCommonTools.goToEditSpecial(
                params.row,
                mainObject,
                specialtype
              );
            }}
            showInMenu={
              inmenu != undefined && inmenu.indexOf("editspecial") != -1
                ? true
                : false
            }
          />
        ) : (
          <></>
        ),
        options &&
        options.indexOf("detail") != -1 &&
        RouteTools.checkRoutePermission(
          RouteTools.getPmHash_Get(mainObject)
        ) ? (
          <GridActionsCellItem
            icon={<FileCopyIcon />}
            label={LL("BTLabList_Detail")}
            title={LL("BTTitleList_Detail")}
            onClick={() => {
              ComponentCommonTools.goToDetails(params.row, mainObject);
            }}
            showInMenu={
              inmenu != undefined && inmenu.indexOf("detail") != -1
                ? true
                : false
            }
          />
        ) : (
          <></>
        ),
        options &&
        options.indexOf("detailspecial") != -1 &&
        RouteTools.checkRoutePermission(
          RouteTools.getPmHash_Get(specialtype ?? "")
        ) ? (
          <GridActionsCellItem
            icon={<FileCopyIcon />}
            label={LL("BTLabList_Detail")}
            title={LL("BTTitleList_Detail")}
            onClick={() => {
              ComponentCommonTools.goToDetailsSpecial(
                params.row,
                mainObject,
                specialtype
              );
            }}
            showInMenu={
              inmenu != undefined && inmenu.indexOf("detailspecial") != -1
                ? true
                : false
            }
          />
        ) : (
          <></>
        ),

        options &&
        options.indexOf("delete") !== -1 &&
        CommonTools.processCanDelete(params.row) &&
        RouteTools.checkRoutePermission(
          RouteTools.getPmHash_Delete(specialtype ? specialtype : mainObject)
        ) ? (
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label={LL("BTLabList_Delete")}
            title={LL("BTTitleList_Delete")}
            onClick={() => {
              if (deleteFun) deleteFun(params.row);
            }}
            showInMenu={
              inmenu != undefined && inmenu.indexOf("delete") != -1
                ? true
                : false
            }
          />
        ) : (
          <></>
        ),
        options && options.includes("children") ? (
          <GridActionsCellItem
            icon={<ChildCareRoundedIcon />}
            label={LL("BTLabList_children")}
            title={LL("BTTitleList_children")}
            onClick={() => {
              if (cbParent) cbParent(params.row);
            }}
            showInMenu={
              inmenu != undefined && inmenu.includes("children") ? true : false
            }
          />
        ) : (
          <></>
        ),
        options &&
        options.indexOf("faqanswer") != -1 &&
        RouteTools.checkRoutePermission(
          RouteTools.getPmHash_List("faqanswer")
        ) ? (
          <GridActionsCellItem
            icon={<QuestionAnswerIcon />}
            label={LL("BTLabList_faqanswer")}
            title={LL("BTTitleList_faqanswer")}
            onClick={() => {
              ComponentCommonTools.goToSpecial(
                params.row,
                mainObject,
                "faqanswer"
              );
            }}
            showInMenu={
              inmenu != undefined && inmenu.indexOf("faqanswer") != -1
                ? true
                : false
            }
          />
        ) : (
          <></>
        ),
        options &&
        options.indexOf("offer") != -1 &&
        RouteTools.checkRoutePermission(RouteTools.getPmHash_List("offer")) ? (
          <GridActionsCellItem
            icon={<LocalOfferIcon />}
            label={LL("BTLabList_offer")}
            title={LL("BTTitleList_offer")}
            onClick={() => {
              ComponentCommonTools.goToSpecial(params.row, mainObject, "offer");
            }}
            showInMenu={
              inmenu != undefined && inmenu.indexOf("offer") != -1
                ? true
                : false
            }
          />
        ) : (
          <></>
        ),
        options &&
        options.indexOf("deliverymethodoffer") != -1 &&
        RouteTools.checkRoutePermission(
          RouteTools.getPmHash_List("deliverymethodoffer")
        ) ? (
          <GridActionsCellItem
            icon={<LocalOfferIcon />}
            label={LL("BTLabList_deliverymethodoffer")}
            title={LL("BTTitleList_deliverymethodoffer")}
            onClick={() => {
              ComponentCommonTools.goToSpecial(
                params.row,
                mainObject,
                "deliverymethodoffer"
              );
            }}
            showInMenu={
              inmenu != undefined && inmenu.indexOf("deliverymethodoffer") != -1
                ? true
                : false
            }
          />
        ) : (
          <></>
        ),
        options &&
        options.indexOf("faqrelation") != -1 &&
        RouteTools.checkRoutePermission(
          RouteTools.getPmHash_List("faqrelation")
        ) ? (
          <GridActionsCellItem
            icon={<QuizIcon />}
            label={LL("BTLabList_faqrelation")}
            title={LL("BTTitleList_faqrelation")}
            onClick={() => {
              ComponentCommonTools.goToSpecial(
                params.row,
                mainObject,
                "faqrelation"
              );
            }}
            showInMenu={
              inmenu != undefined && inmenu.indexOf("faqrelation") != -1
                ? true
                : false
            }
          />
        ) : (
          <></>
        ),
        options &&
        options.indexOf("productfilter") != -1 &&
        RouteTools.checkRoutePermission(
          RouteTools.getPmHash_List("productfilter")
        ) ? (
          ComponentCommonTools.processOptionActionColumn(
            "productfilter",
            LL,
            params,
            inmenu,
            mainObject,
            <FilterListIcon />
          )
        ) : (
          <></>
        ),
        options &&
        options.indexOf("productwishlist") != -1 &&
        RouteTools.checkRoutePermission(
          RouteTools.getPmHash_List("productwishlist")
        ) ? (
          ComponentCommonTools.processOptionActionColumn(
            "productwishlist",
            LL,
            params,
            inmenu,
            mainObject,
            <Favorite />
          )
        ) : (
          <></>
        ),
        options &&
        options.indexOf("aclroles") != -1 &&
        RouteTools.checkRoutePermission(
          RouteTools.getPmHash_List("aclroles")
        ) ? (
          ComponentCommonTools.processOptionActionColumn(
            "aclroles",
            LL,
            params,
            inmenu,
            mainObject,
            <VerifiedUser />
          )
        ) : (
          <></>
        ),
        options &&
        options.indexOf("orderjournal") != -1 &&
        RouteTools.checkRoutePermission(
          RouteTools.getPmHash_List("orderjournal")
        ) ? (
          ComponentCommonTools.processOptionActionColumn(
            "orderjournal",
            LL,
            params,
            inmenu,
            mainObject,
            <TimelineIcon />
          )
        ) : (
          <></>
        ),
        options &&
        options.indexOf("orderproduct") != -1 &&
        RouteTools.checkRoutePermission(
          RouteTools.getPmHash_List("orderproduct")
        ) ? (
          ComponentCommonTools.processOptionActionColumn(
            "orderproduct",
            LL,
            params,
            inmenu,
            mainObject,
            <ShoppingBagIcon />
          )
        ) : (
          <></>
        ),
        options &&
        options.indexOf("offerfilter") != -1 &&
        RouteTools.checkRoutePermission(
          RouteTools.getPmHash_List("offerfilter")
        ) ? (
          ComponentCommonTools.processOptionActionColumn(
            "offerfilter",
            LL,
            params,
            inmenu,
            mainObject,
            <FilterListIcon />,
            ComponentCommonTools.goToSpecialOfferFilter
          )
        ) : (
          <></>
        ),
        options &&
        options.indexOf("orderproductfilter") != -1 &&
        RouteTools.checkRoutePermission(
          RouteTools.getPmHash_List("orderproductfilter")
        ) ? (
          ComponentCommonTools.processOptionActionColumn(
            "orderproductfilter",
            LL,
            params,
            inmenu,
            mainObject,
            <FilterListIcon />,
            ComponentCommonTools.goToSpecialOrderProductFilter
          )
        ) : (
          <></>
        ),
        options &&
        options.indexOf("filterdictinar") != -1 &&
        CommonTools.processObjectField(params, ["row", "type"]) ==
          FilterType.DICTIONAR.toString() &&
        RouteTools.checkRoutePermission(
          RouteTools.getPmHash_List("filterdictinar")
        ) ? (
          ComponentCommonTools.processOptionActionColumn(
            "filterdictinar",
            LL,
            params,
            inmenu,
            mainObject,
            <FilterListIcon />,
            ComponentCommonTools.goToFilterDictionar
          )
        ) : (
          <></>
        ),
      ],
    };

    return obj;
  };

  public static goToFilterDictionar = (
    _obj: any,
    mainObject: any,
    str: string
  ) => {
    // Should return error route
    if (_obj === undefined) return RouteTools.setHistory("/", {});
    if (typeof _obj !== "object") return RouteTools.setHistory("/", {});
    if (!_obj.hasOwnProperty("id")) return RouteTools.setHistory("/", {});
    if (!mainObject) return RouteTools.setHistory("/", {});
    const idObject = _obj.id;
    if (!idObject) return RouteTools.setHistory("/", {});
    return FilterDto.goToFilterDictionar(idObject);
  };

  public static processObjectField = (obj: any, field: any) => {
    if (obj === undefined || obj === null) return <></>;
    if (field === undefined || !obj.hasOwnProperty(field)) return <></>;
    if (obj[field] === undefined || obj[field] === null) return <></>;
    if (obj[field] === 0) {
      return <>0</>;
    }
    if (!obj[field]) return <></>;

    const label = obj[field];
    return <>{label}</>;
  };

  public static handleSuccessAddAndEditSpecial = (
    result: any,
    cbparameters: any
  ) => {
    const mainObject = cbparameters?.mainObject;
    const idLanguage = cbparameters?.idLanguage ? cbparameters?.idLanguage : "";
    const idRoute = cbparameters?.idRoute ? cbparameters?.idRoute : "";
    // Should return error route
    if (!mainObject) return RouteTools.setHistory("/", {});
    if (!result) return RouteTools.setHistory("/", {});
    if (!result.obj) return RouteTools.setHistory("/", {});
    if (!result.obj.hasOwnProperty("id")) return RouteTools.setHistory("/", {});

    let idObject = result.obj.id;
    if (idRoute !== "") idObject = idRoute;
    // const mainUrl = CommonTools.generateMainUrl(mainObject);
    const mainUrl =
      "/" +
      cbparameters.parentType +
      "/" +
      cbparameters.parentId +
      "/" +
      cbparameters.specialPart;
    const state = {
      obj: result.obj,
      _mainurl: mainUrl,
      idLanguage: idLanguage,
    };
    const detailUrl =
      "/" +
      cbparameters.parentType +
      "/" +
      cbparameters.parentId +
      "/" +
      cbparameters.specialPart +
      "/" +
      idObject;
    const route = detailUrl;

    if (cbparameters.hasOwnProperty("cb") && cbparameters.cb) cbparameters.cb();
    return RouteTools.setHistory(route, state);

    // return RouteTools.setHistory(mainUrl, state);
  };

  public static handleSuccessAddAndEditSpecialThirdLevel = (
    result: any,
    cbparameters: any
  ) => {
    const mainObject = cbparameters?.mainObject;
    const idLanguage = cbparameters?.idLanguage ? cbparameters?.idLanguage : "";
    const idRoute = cbparameters?.idRoute ? cbparameters?.idRoute : "";
    // Should return error route
    if (!mainObject) return RouteTools.setHistory("/", {});
    if (!result) return RouteTools.setHistory("/", {});
    if (!result.obj) return RouteTools.setHistory("/", {});
    if (!result.obj.hasOwnProperty("id")) return RouteTools.setHistory("/", {});

    let idObject = result.obj.id;
    if (idRoute !== "") idObject = idRoute;
    // const mainUrl = CommonTools.generateMainUrl(mainObject);
    const mainUrl =
      "/" +
      cbparameters.firstLevelParentType +
      "/" +
      cbparameters.firstLevelParentId +
      "/" +
      cbparameters.parentType +
      "/" +
      cbparameters.parentId +
      "/" +
      cbparameters.specialPart;
    const state = {
      obj: result.obj,
      _mainurl: mainUrl,
      idLanguage: idLanguage,
    };
    const detailUrl =
      "/" +
      cbparameters.firstLevelParentType +
      "/" +
      cbparameters.firstLevelParentId +
      "/" +
      cbparameters.parentType +
      "/" +
      cbparameters.parentId +
      "/" +
      cbparameters.specialPart +
      "/" +
      idObject;
    const route = detailUrl;

    if (cbparameters.hasOwnProperty("cb") && cbparameters.cb) cbparameters.cb();
    return RouteTools.setHistory(route, state);

    // return RouteTools.setHistory(mainUrl, state);
  };

  public static handleSuccessAddAndEdit = (result: any, cbparameters: any) => {
    const mainObject = cbparameters?.mainObject;
    const idLanguage = cbparameters?.idLanguage ? cbparameters?.idLanguage : "";
    const idRoute = cbparameters?.idRoute ? cbparameters?.idRoute : "";
    const anchor = cbparameters?.anchor ? cbparameters?.anchor : "";

    // Should return error route
    if (!mainObject) return RouteTools.setHistory("/", {});
    if (!result) return RouteTools.setHistory("/", {});
    if (!result.hasOwnProperty("obj")) return RouteTools.setHistory("/", {});
    if (!result.obj) return RouteTools.setHistory("/", {});
    if (!result.obj.hasOwnProperty("id")) return RouteTools.setHistory("/", {});

    let idObject = result.obj.id;
    if (idRoute !== "") idObject = idRoute;
    const mainUrl = CommonTools.generateMainUrl(mainObject);
    const state = {
      obj: result.obj,
      _mainurl: mainUrl,
      idLanguage: idLanguage,
    };
    const detailUrl = CommonTools.generateDetailUrl(mainObject, idObject);
    const route = detailUrl;
    if (cbparameters.hasOwnProperty("cb") && cbparameters.cb) cbparameters.cb();
    return RouteTools.setHistory(route, state, anchor);
  };

  public static goToMainPage = (result: any, cbparameters: any) => {
    // logger("ResultObj", result);
    const mainObject = cbparameters?.mainObject;
    // Should return error route
    if (!mainObject) return RouteTools.setHistory("/", {});
    if (result === undefined) return RouteTools.setHistory("/", {});
    if (result.obj === undefined) return RouteTools.setHistory("/", {});
    const mainUrl = CommonTools.generateMainUrl(mainObject);
    const state = { _mainurl: mainUrl };
    const route = mainUrl;
    return RouteTools.setHistory(route, state);
  };

  public static goToDetails = (_obj: any, mainObject: any) => {
    // Should return error route
    if (_obj === undefined) return RouteTools.setHistory("/", {});
    if (typeof _obj !== "object") return RouteTools.setHistory("/", {});
    if (!_obj.hasOwnProperty("id")) return RouteTools.setHistory("/", {});
    if (!mainObject) return RouteTools.setHistory("/", {});
    const idObject = _obj.id;
    const mainUrl = CommonTools.generateMainUrl(mainObject);
    const detailUrl = CommonTools.generateDetailUrl(mainObject, idObject);
    const state = { obj: _obj, _mainurl: mainUrl };
    const route = detailUrl;

    return RouteTools.setHistory(route, state);
  };

  public static goToDetailsSpecial = (
    _obj: any,
    mainObject: any,
    specialtype?: string
  ) => {
    // Should return error route
    if (_obj === undefined) return RouteTools.setHistory("/", {});
    if (typeof _obj !== "object") return RouteTools.setHistory("/", {});
    if (!_obj.hasOwnProperty("id")) return RouteTools.setHistory("/", {});
    if (!mainObject) return RouteTools.setHistory("/", {});
    const idObject = _obj.id;
    // logger("goToDetailsSpecial", _obj);
    if (!specialtype) return RouteTools.setHistory("/notfound", {});
    const specialRoute = ComponentCommonTools.processSpecialRoutes(
      specialtype,
      _obj,
      ACTION_TABLE
    );
    const mainUrl = specialRoute;
    const detailUrl = specialRoute + "/" + idObject;
    const state = { obj: _obj, _mainurl: mainUrl };
    const route = detailUrl;

    return RouteTools.setHistory(route, state);
  };

  public static goToSpecialOrderProductFilter = (
    _obj: any,
    mainObject: any,
    str: string
  ) => {
    if (_obj === undefined) return RouteTools.setHistory("/", {});
    if (typeof _obj !== "object") return RouteTools.setHistory("/", {});
    if (!_obj.hasOwnProperty("id")) return RouteTools.setHistory("/", {});
    if (!mainObject) return RouteTools.setHistory("/", {});
    const idObject = _obj.id;
    const idOrder = CommonTools.processObjectField(_obj, ["idorder"]);
    const mainUrl = CommonTools.generateMainUrl(mainObject);

    const editUrl = CommonTools.generateThirdLevelUrl(
      mainObject,
      str,
      "orderproduct",
      idOrder,
      idObject
    );
    const state = { obj: _obj, _mainurl: mainUrl };
    const route = editUrl;

    return RouteTools.setHistory(route, state);
  };

  public static goToDetailsSpecialThirdLevel = (
    _obj: any,
    mainObject: any,
    specialtype?: string
  ) => {
    // Should return error route
    if (_obj === undefined) return RouteTools.setHistory("/", {});
    if (typeof _obj !== "object") return RouteTools.setHistory("/", {});
    if (!_obj.hasOwnProperty("id")) return RouteTools.setHistory("/", {});
    if (!mainObject) return RouteTools.setHistory("/", {});
    const idObject = _obj.id;
    // logger("goToDetailsSpecial", _obj);
    if (!specialtype) return RouteTools.setHistory("/notfound", {});
    const specialRoute = ComponentCommonTools.processSpecialRoutes(
      specialtype,
      _obj
    );

    const mainUrl = mainObject + "/" + specialRoute;

    const detailUrl = mainObject + "/" + specialRoute + "/" + idObject;
    const state = { obj: _obj, _mainurl: mainUrl };
    const route = detailUrl;

    return RouteTools.setHistory(route, state);
  };

  public static processSpecialRoutes = (
    specialType: string,
    obj: any,
    action?: string
  ): string => {
    if (specialType === "faqanswer") {
      return (
        "/faq/" +
        CommonTools.processObjectField(obj, ["idfaq"]) +
        "/" +
        specialType
      );
    } else if (specialType === "productwishlist") {
      return (
        "/user/" +
        CommonTools.processObjectField(obj, ["iduser"]) +
        "/" +
        specialType
      );
    } else if (specialType === "aclroles") {
      return (
        "/role/" +
        CommonTools.processObjectField(obj, ["idrole"]) +
        "/" +
        specialType
      );
    } else if (specialType === "offer" || specialType === "productfilter") {
      return (
        "/product/" +
        CommonTools.processObjectField(obj, ["idproduct"]) +
        "/" +
        specialType
      );
    } else if (
      specialType === "orderjournal" ||
      specialType === "orderproduct"
    ) {
      return (
        "/order/" +
        CommonTools.processObjectField(obj, ["idorder"]) +
        "/" +
        specialType
      );
    } else if (specialType === "deliverymethodoffer") {
      return (
        "/deliverymethod/" +
        CommonTools.processObjectField(obj, ["iddeliverymethod"]) +
        "/" +
        specialType
      );
    } else if (specialType === "offerfilter") {
      if (action === ACTION_TABLE) {
        return specialType;
      } else {
        return (
          CommonTools.processObjectField(obj, ["idoffer"]) + "/" + specialType
        );
      }
    } else if (specialType === "orderproductfilter") {
      if (action === ACTION_TABLE) {
        return specialType;
      } else {
        return (
          CommonTools.processObjectField(obj, ["idorderproduct"]) +
          "/" +
          specialType
        );
      }
    } else {
      return `/${CommonTools.processObjectField(obj, [
        "parent",
      ])}/${CommonTools.processObjectField(obj, ["id_parent"])}/${specialType}`;
    }
  };
  public static goToEditSpecial = (
    _obj: any,
    mainObject: any,
    specialtype?: string
  ) => {
    // Should return error route
    if (_obj === undefined) return RouteTools.setHistory("/", {});
    if (typeof _obj !== "object") return RouteTools.setHistory("/", {});
    if (!_obj.hasOwnProperty("id")) return RouteTools.setHistory("/", {});
    if (!mainObject) return RouteTools.setHistory("/", {});
    const idObject = _obj.id;
    if (!specialtype) return RouteTools.setHistory("/notfound", {});
    const specialRoute = ComponentCommonTools.processSpecialRoutes(
      specialtype,
      _obj,
      ACTION_TABLE
    );
    const mainUrl = specialtype;
    const detailUrl = specialRoute + "/edit/" + idObject;
    const state = { obj: _obj, _mainurl: mainUrl };
    const route = detailUrl;

    return RouteTools.setHistory(route, state);
  };

  public static goToDetailsKeepingMainObject = (
    _obj: any,
    mainObject: any,
    otherMainObject?: string,
    field?: string
  ) => {
    // Should return error route

    if (_obj === undefined) return RouteTools.setHistory("/", {});
    if (typeof _obj !== "object") return RouteTools.setHistory("/", {});
    if (!_obj.hasOwnProperty("id")) return RouteTools.setHistory("/", {});
    if (!mainObject) return RouteTools.setHistory("/", {});
    if (otherMainObject == undefined) otherMainObject = "Course";
    if (field == undefined) field = "idcourse";
    if (!_obj.hasOwnProperty(field)) return RouteTools.setHistory("/", {});
    const idObject = _obj.id;
    const mainUrl = CommonTools.generateMainUrl(otherMainObject);
    const courseDetailUrl = CommonTools.generateDetailUrl(
      otherMainObject,
      _obj[field]
    );
    const detailUrl = CommonTools.generateDetailUrl(mainObject, idObject);
    const state = {
      obj: _obj,
      _mainurl: mainUrl,
      _detailCourseUrl: courseDetailUrl,
    };
    const route = detailUrl;

    return RouteTools.setHistory(route, state);
  };

  public static goToEdit = (_obj: any, mainObject: any) => {
    // Should return error route
    if (_obj === undefined) return RouteTools.setHistory("/", {});
    if (typeof _obj !== "object") return RouteTools.setHistory("/", {});
    if (!_obj.hasOwnProperty("id")) return RouteTools.setHistory("/", {});
    if (!mainObject) return RouteTools.setHistory("/", {});
    const idObject = _obj.id;
    const mainUrl = CommonTools.generateMainUrl(mainObject);
    const editUrl = CommonTools.generateEditUrl(mainObject, idObject);
    const state = { obj: _obj, _mainurl: mainUrl };
    const route = editUrl;

    return RouteTools.setHistory(route, state);
  };

  public static goToGallery = (_obj: any, mainObject: any) => {
    // Should return error route
    if (_obj === undefined) return RouteTools.setHistory("/", {});
    if (typeof _obj !== "object") return RouteTools.setHistory("/", {});
    if (!_obj.hasOwnProperty("id")) return RouteTools.setHistory("/", {});
    if (!mainObject) return RouteTools.setHistory("/", {});
    const idObject = _obj.id;
    const mainUrl = CommonTools.generateMainUrl(mainObject);
    // const editUrl = CommonTools.generateEditUrl(mainObject, idObject);
    const editUrl = CommonTools.generateGalleryUrl(mainObject, idObject);
    // logger("goToGallery -> editUrl editUrl", editUrl);
    const state = { obj: _obj, _mainurl: mainUrl };
    const route = editUrl;

    return RouteTools.setHistory(route, state);
  };

  public static goToAttachment = (_obj: any, mainObject: any) => {
    // Should return error route
    if (_obj === undefined) return RouteTools.setHistory("/", {});
    if (typeof _obj !== "object") return RouteTools.setHistory("/", {});
    if (!_obj.hasOwnProperty("id")) return RouteTools.setHistory("/", {});
    if (!mainObject) return RouteTools.setHistory("/", {});
    const idObject = _obj.id;
    const mainUrl = CommonTools.generateMainUrl(mainObject);
    // const editUrl = CommonTools.generateEditUrl(mainObject, idObject);
    const editUrl = CommonTools.generateAttachmentUrl(mainObject, idObject);
    // logger("goToAttachment -> editUrl editUrl", editUrl);
    const state = { obj: _obj, _mainurl: mainUrl };
    const route = editUrl;

    return RouteTools.setHistory(route, state);
  };

  public static goToSpecial = (_obj: any, mainObject: any, str: string) => {
    // Should return error route
    if (_obj === undefined) return RouteTools.setHistory("/", {});
    if (typeof _obj !== "object") return RouteTools.setHistory("/", {});
    if (!_obj.hasOwnProperty("id")) return RouteTools.setHistory("/", {});
    if (!mainObject) return RouteTools.setHistory("/", {});
    const idObject = _obj.id;
    const mainUrl = CommonTools.generateMainUrl(mainObject);
    // const editUrl = CommonTools.generateEditUrl(mainObject, idObject);
    const editUrl = CommonTools.generateSpecialUrl(mainObject, str, idObject);
    // logger("goToAttachment -> editUrl editUrl", editUrl);
    const state = { obj: _obj, _mainurl: mainUrl };
    const route = editUrl;

    return RouteTools.setHistory(route, state);
  };

  public static goToSpecialOfferFilter = (
    _obj: any,
    mainObject: any,
    str: string
  ) => {
    // Should return error route
    if (_obj === undefined) return RouteTools.setHistory("/", {});
    if (typeof _obj !== "object") return RouteTools.setHistory("/", {});
    if (!_obj.hasOwnProperty("id")) return RouteTools.setHistory("/", {});
    if (!mainObject) return RouteTools.setHistory("/", {});
    const idObject = _obj.id;
    const idProduct = CommonTools.processObjectField(_obj, ["idproduct"]);
    const mainUrl = CommonTools.generateMainUrl(mainObject);
    // const editUrl = CommonTools.generateEditUrl(mainObject, idObject);
    const editUrl = CommonTools.generateOfferFilter(
      mainObject,
      str,
      idProduct,
      idObject
    );
    // logger("goToAttachment -> editUrl editUrl", editUrl);
    const state = { obj: _obj, _mainurl: mainUrl };
    const route = editUrl;

    return RouteTools.setHistory(route, state);
  };

  public static goToVideo = (_obj: any, mainObject: any) => {
    // Should return error route
    if (_obj === undefined) return RouteTools.setHistory("/", {});
    if (typeof _obj !== "object") return RouteTools.setHistory("/", {});
    if (!_obj.hasOwnProperty("id")) return RouteTools.setHistory("/", {});
    if (!mainObject) return RouteTools.setHistory("/", {});
    const idObject = _obj.id;
    const mainUrl = CommonTools.generateMainUrl(mainObject);
    // const editUrl = CommonTools.generateEditUrl(mainObject, idObject);
    const editUrl = CommonTools.generateVideoUrl(mainObject, idObject);
    // logger("goToVideo -> editUrl editUrl", editUrl);
    const state = { obj: _obj, _mainurl: mainUrl };
    const route = editUrl;

    return RouteTools.setHistory(route, state);
  };
}

export { ComponentCommonTools };
