import React, { useEffect } from "react";
import { GoogleMap, InfoWindowF, MarkerF } from "@react-google-maps/api";
import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { HtmlTag } from "../display/HtmlTag";

type Props = {
  lat?: number;
  lng?: number;
  setLatAndLng: (lat: number, lng: number) => void;
  name?: string;
  description?: string;
  valueLat?: number;
  valueLng?: number;
};

const GoogleMapsSelector: React.FC<Props> = React.memo(
  ({
    lat = 47.01638,
    lng = 28.847504,
    setLatAndLng,
    name = "",
    description = "",
    valueLat,
    valueLng,
  }) => {
    const [open, setOpen] = useState(false);
    const defaultSelected: google.maps.LatLng | null =
      valueLat && valueLng ? new google.maps.LatLng(valueLat, valueLng) : null;
    const [selected, setSelected] = useState<google.maps.LatLng | null>(
      defaultSelected
    );

    const center = {
      lat: lat,
      lng: lng,
    };

    const onClick = (e: google.maps.MapMouseEvent) => {
      setOpen(false);
      setSelected(e.latLng);
      if (e.latLng) setLatAndLng(e.latLng.lat(), e.latLng.lng());
    };

    const onDoubleClick = () => {
      setSelected(null);
      setOpen(false);
      setLatAndLng(0, 0);
    };

    useEffect(() => {
      if (valueLat && valueLng) {
        setSelected(new google.maps.LatLng(valueLat, valueLng));
      } else {
        setSelected(null);
      }
    }, [valueLat, valueLng]);

    return (
      <GoogleMap
        mapContainerStyle={{
          width: "100%",
          height: "100%",
        }}
        center={center}
        zoom={13}
        clickableIcons={false}
        onClick={onClick}
        onDblClick={onDoubleClick}
        
      >
        {selected && (
          <MarkerF
            position={selected}
            draggable={true}
            onClick={() => {
              setOpen(true);
            }}
            onDrag={onClick}
          >
            {open ? (
              <InfoWindowF
                onCloseClick={() => {
                  setOpen(false);
                }}
              >
                <Box>
                  <Typography variant="h6">{name}</Typography>
                  <HtmlTag content={description} />
                </Box>
              </InfoWindowF>
            ) : null}
          </MarkerF>
        )}
      </GoogleMap>
    );
  }
);

export { GoogleMapsSelector };
