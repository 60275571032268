import { Box } from "@mui/material";
import { ButtonWithLoading } from "components/elements/button/ButtonWithLoading";
import { MyCKEditor } from "components/elements/form/MyCKEditor";

import { MyTextField } from "components/elements/form/MyTextField";
import { GoogleMapsSelector } from "components/elements/map/GoogleMapsSelector";
import { StatusSelect } from "components/elements/select/StatusSelect";
import { LanguageSelect } from "components/static/language/LanguageSelect";
import { ContactPointDTO } from "dto/static/contactpoint.dto";
import { useForm } from "hooks/useForm";
import { useLabel } from "hooks/useLabel";
import React from "react";

import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObject: ContactPointDTO;
  onSubmit: (data: ContactPointDTO) => void;
  loading: boolean;
  forAdd?: boolean;
};
const FormContactPoint: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
  forAdd = false,
}) => {
  const { LL } = useLabel();

  const [obj, disabled, setObjField, , setLanguage, setObject,vResults] =
    useForm<ContactPointDTO>(
      defaultObject,
      RequiredValidator.getValidators(["_name"])
    );

  const handleSetLatAndLng = (lat: number, lng: number) => {
    let t = JSON.parse(JSON.stringify(obj));

    t["lat"] = lat;
    t["lng"] = lng;
    setObject(t);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <MyTextField
            field="lat"
            label={LL("field_lat")}
            setObjectField={setObjField}
            value={obj.lat}
            type="number"
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="lng"
            label={LL("field_lng")}
            setObjectField={setObjField}
            value={obj.lng}
            type="number"
            vResults={vResults}
          />
        </Box>
        <Box
          mt={3}
          sx={{
            width: "400px",
            height: "400px",
          }}
        >
          <GoogleMapsSelector
            setLatAndLng={handleSetLatAndLng}
            valueLat={obj.lat}
            valueLng={obj.lng}
            name={obj._name}
            description={obj._description}
          />
        </Box>
        {forAdd && (
          <Box mt={3}>
            <LanguageSelect value={obj._idlang ?? ""} setValue={setLanguage} />
          </Box>
        )}
        <Box mt={3}>
          <StatusSelect setObjField={setObjField} value={obj.status} />
        </Box>

        <Box mt={3}>
          <MyTextField
            field="_name"
            label={LL("field_name")}
            setObjectField={setObjField}
            value={obj._name}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyCKEditor
            field="_description"
            setObjectField={setObjField}
            data={obj._description}
          />
        </Box>

        <Box mt={3}>
          <ButtonWithLoading
            type="submit"
            disabled={loading || disabled}
            loading={loading}
          >
            {LL("btn_submit")}
          </ButtonWithLoading>
        </Box>
      </form>
    </Box>
  );
};

export { FormContactPoint };
