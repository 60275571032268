import React, { useEffect, useLayoutEffect, useState } from "react";
import { CookiesProvider } from "react-cookie";
import theme from "assets/themes/theme";

import { ThemeProvider } from "@mui/material/styles";
import { ResourceProvider } from "providers/ResourceProvider";
import { MessageProvider } from "providers/MessageProvider";
import { LabelProvider } from "providers/LabelProvider";
import { RoutesProvider } from "providers/RoutesProvider";
import { JWTProvider } from "providers/JWTProvider";
import { UserProvider } from "providers/UserProvider";
import { LanguageProvider } from "providers/LanguageProvider";
import { ConfigProvider } from "providers/ConfigProvider";
import GeneralAxiosRepository from "repositories/generalaxios.repository";
import { ServerError } from "components/general/ServerError";
import { LocalStorageTools } from "api/localstorage.api";
import { GoogleMapsProvider } from "components/elements/map/GoogleMapsProvider";
import { AclProvider } from "providers/AclProvider";

const App: React.FC = () => {
  const [_theme, setTheme] = useState({});
  const [serverError, setServerError] = useState<boolean>(false);
  useEffect(() => {
    setTheme(theme);
  }, []);

  const handleReload = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const processServerError = () => {
    setServerError(true);
  };

  useLayoutEffect(() => {
    GeneralAxiosRepository.setReloadFunction(handleReload);
    GeneralAxiosRepository.setServerError(processServerError);
    GeneralAxiosRepository.setHandleUnauthorized(handleUnauthorized);
  }, []);

  const handleUnauthorized = () => {
    LocalStorageTools.saveValue("token", "");
    LocalStorageTools.saveValue("remember_token", "");
  };

  if (serverError) return <ServerError />;
  return (
    <GoogleMapsProvider>
      <ThemeProvider theme={_theme}>
        <CookiesProvider
          defaultSetOptions={{
            sameSite: "strict",
          }}
        >
          <MessageProvider>
            <JWTProvider>
              <AclProvider>
                <LanguageProvider>
                  <UserProvider>
                    <ResourceProvider>
                      <LabelProvider>
                        <ConfigProvider>
                          <RoutesProvider />
                        </ConfigProvider>
                      </LabelProvider>
                    </ResourceProvider>
                  </UserProvider>
                </LanguageProvider>
              </AclProvider>
            </JWTProvider>
          </MessageProvider>
        </CookiesProvider>
      </ThemeProvider>
    </GoogleMapsProvider>
  );
};

export default App;
