import { DictionaryDTO } from "dto/app/dictionary.dto";
import { CustomJWTPayload, JWTDto } from "dto/app/jwt.dto";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { CommonTools } from "tools/utils/common.tool";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { FilterTools } from "tools/utils/filter.tool";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";
import { UserConfigType } from "tools/types/userconfigtype";

export class SignupDto implements Idto {
  email: string;
  password: string;

  constructor(email?: string, password?: string) {
    this.email = email ?? "";
    this.password = password ?? "";
  }
}

export class LoginDto implements Idto {
  email: string;
  password: string;

  constructor(email?: string, password?: string) {
    this.email = email ?? "";
    this.password = password ?? "";
  }

  static parseFromLoginSiteDto(dto: LoginSiteDto): LoginDto {
    return new LoginDto(dto.email, dto.password);
  }
}

export class LoginSiteDto implements Idto {
  email: string;
  password: string;
  remember: boolean;

  constructor(email?: string, password?: string) {
    this.email = email ?? "";
    this.password = password ?? "";
    this.remember = false;
  }
}

export class UserDto implements Idto {
  id?: number;
  email?: string;
  screen_name?: string;
  roles?: number[];
  scopes?: string[];
  configs?: DictionaryDTO[];
  password?: string;
  status?: number;

  constructor(
    id?: number,
    email?: string,
    screen_name?: string,
    roles?: number[],
    scopes?: string[],
    configs?: DictionaryDTO[],
    password?: string,
    status?: number
  ) {
    this.id = id || 0;
    this.email = email || "";
    this.screen_name = screen_name || "";
    this.roles = roles || [];
    this.scopes = scopes || [];
    this.configs = configs || [];
    this.password = password || "";
    this.status = status || Status.USER_ACTIVE;
  }
  static parseFromTokenPayload(payload: CustomJWTPayload): UserDto | null {
    if (
      !CommonTools.processObjectField(payload, ["u_id"]) ||
      CommonTools.processObjectField(payload, ["u_id"]) === "0"
    )
      return null;
    const obj = new UserDto();
    obj.id = payload.u_id;
    obj.email = payload.u_screenname.includes("@") ? payload.u_screenname : "";
    obj.screen_name = payload.u_screenname;
    obj.roles = payload.u_roles;
    obj.scopes = payload.u_scopes;
    obj.configs = payload.u_configs;

    return obj;
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Email",
        value: ["email"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Screen_name",
        value: ["screen_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_CONFIG_TEXT,
        label: "Config",
        value: ["configs"],
        options: {
          LL: UserConfigType.LL,
        },
      },
      {
        typeField: Types.FIELD_TYPE_ARRAY,
        label: "Roles",
        value: ["rolesObjects"],
        options: {
          fields: ["role"],
        },
      }
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "id", "#"),
      ComponentCommonTools.columns_StandardColumn(LL, "email"),
      ComponentCommonTools.columns_StandardColumn(LL, "screen_name"),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete"],
        ["detail"],
        cbParent,
        specialType
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("user", true));
    obj.addFilter(t);

    return obj;
  }
}

export class AuthDto implements Idto {
  user?: UserDto;
  token?: JWTDto;
  remember?: string;
}

export class ChangePasswordDto implements Idto {
  password: string;
  confirmpassword: string;

  constructor(password?: string, confirmpassword?: string) {
    this.password = password ?? "";
    this.confirmpassword = confirmpassword ?? "";
  }
}
