import { Box, IconButton, Stack, Tab, Tabs, Typography } from "@mui/material";
import { MyBreadcrumbs } from "components/elements/breadcrumb/MyBreadcrumbs";

import { DataDisplayBox } from "components/elements/display/DataDisplayBox";

import { FormUser } from "components/user/user/FormUser";
import { BreadcrumbDto } from "dto/app/breadcrumb.dto";
import { ChangePasswordDto, UserDto } from "dto/user/user.dto";
import { useUser } from "hooks/useUser";
import { IPage } from "interfaces/page.interface";
import React, { useState } from "react";
import { CommonTools } from "tools/utils/common.tool";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { NavButton } from "components/elements/button/NavButton";
import { useLabel } from "hooks/useLabel";
import { Loading } from "components/elements/loading/Loading";
import { Config } from "tools/utils/config";
import { RouteTools } from "tools/utils/route.tool";
import { TabPanel } from "components/elements/display/TabPanel";
import { ChangePasswordForm } from "components/authentication/ChangePasswordForm";
import { CallbackType } from "interfaces/commontypes.interface";


const ProfilePage: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const { user, changeProfile, changePassword } = useUser();
  const { LL } = useLabel();
  const [loading, setLoading] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState<string>(
    CommonTools.getAnchor(currentRoute, Config.DEFAULT_TAB)
  );
  const handleChangeTab = (event: React.SyntheticEvent, newValue: any) => {
    setTabValue(newValue);
    RouteTools.setAnchor(newValue);
  };
  const cb = () => {
    setLoading(false);
  };
  const onSubmitChangePassword = (obj: ChangePasswordDto, cb: CallbackType) => {
    
    changePassword(obj, cb);
  };
  const onSubmit = (obj: UserDto) => {
    if (!user) return;
    if (!user.id) return;
    setLoading(true);
    changeProfile(obj, cb);
  };

  if (!currentRoute) return null;
  if (!mainObject) return null;

  if (!user) return null;
  if (loading) return <Loading />;
  return (
    <Box>
      <MyBreadcrumbs
        objects={BreadcrumbDto.getBreadcrumbList(mainObject)}
        mainObject={mainObject}
      />
      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",

          width: "100%",
        }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          spacing={1}
        >
          <Box>
            <NavButton
              href={"/"}
              sx={{ ml: "auto" }}
              _hstate={{}}
              _mainurl={CommonTools.generateMainUrl(mainObject)}
              component={IconButton}
            >
              <ArrowBackIcon />
            </NavButton>
          </Box>
          <Box sx={{ mr: 10 }}>
            <Typography variant="h4" sx={{ my: 3, ml: 3, pr: 1 }}>
              {LL(mainObject)}
            </Typography>
          </Box>
        </Stack>
      </Box>
      <Box maxWidth="md" className="tabs">
        <Tabs variant="scrollable" value={tabValue} onChange={handleChangeTab}>
          <Tab label={LL("Edit_profile")} value={"generalinfo"} />
          <Tab label={LL("Change_password")} value={"changepassword"} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={"generalinfo"}>
        <DataDisplayBox>
          <Box>
            <Stack
              direction={"row"}
              spacing={1}
              alignItems={"center"}
              justifyContent={"flex-start"}
            >
              <Typography>{LL("Email")}</Typography>
              <Typography>
                {CommonTools.processObjectField(user, ["email"])}
              </Typography>
            </Stack>
          </Box>
          <Box mt={3}>
            <Stack
              direction={"row"}
              spacing={1}
              alignItems={"center"}
              justifyContent={"flex-start"}
            >
              <Typography>{LL("screen_name")}</Typography>
              <Typography>
                {CommonTools.processObjectField(user, ["screen_name"])}
              </Typography>
            </Stack>
          </Box>
          <FormUser
            defaultObject={user}
            onSubmit={onSubmit}
            loading={loading}
            forProfile
          />
        </DataDisplayBox>
      </TabPanel>
      <TabPanel value={tabValue} index={"changepassword"}>
        <DataDisplayBox>
          <ChangePasswordForm onSubmit={onSubmitChangePassword} />
        </DataDisplayBox>
      </TabPanel>
    </Box>
  );
};

export { ProfilePage };
