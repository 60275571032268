import SelectOptions from "dto/app/selectoptions.dto";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { CommonTools } from "tools/utils/common.tool";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";

import { FilterTools } from "tools/utils/filter.tool";
import { Types } from "tools/types/types";
import { Status } from "tools/types/status";
import { CountryDto } from "dto/static/country.dto";
import { LocationDto } from "dto/static/location.dto";
import { DeliveryMethodDto } from "dto/delivery/deliverymethod.dto";
import { ClientType } from "tools/types/clienttype";

import { OrderListColumns } from "components/sale/order/OrderListColumns";

export class OrderDto implements Idto {
  id?: number | string;

  status?: number;
  paystatus?: number;
  paymethod?: number;

  date?: number;
  datedelivery?: number;
  datepayment?: number;

  pricewovat?: number;
  vattotal?: number;
  pricetotal?: number;

  discount?: number;
  realprice?: number;

  priceadvance?: number;
  pricedelivery?: number;

  pricepaid?: number;
  pricedue?: number;

  coments?: string;

  destinatar_idcountry?: number;
  destinatar_idlocation?: number;
  destinatar_locationcode?: string;
  destinatar_address?: string;

  client_id?: number;
  client_name?: string;
  client_mobil?: string;
  client_email?: string;
  client_othercontacts?: string;

  client_type?: number;
  client_fct_fiz_name?: string;
  client_fct_fiz_idno?: string;
  client_fct_fiz_docnumber?: string;
  client_fct_fiz_address?: string;
  client_fct_fiz_phone?: string;

  client_fct_jur_company?: string;
  client_fct_jur_idno?: string;
  client_fct_jur_othercode?: string;
  client_fct_jur_address?: string;
  client_fct_jur_iban?: string;
  client_fct_jur_bank?: string;
  client_fct_jur_phone?: string;

  iddeliverymethod?: number;
  delivery_tracking?: string;
  delivery_tracking_url?: string;
  delivery_comments?: string;

  countryObj?: CountryDto;
  locationObj?: LocationDto;
  deliveryMethodObj?: DeliveryMethodDto;

  _cansetstatusnew?: number;
  _cansetstatusverified?: number;
  _cansetstatusinprocess?: number;
  _cansetstatusprocessed?: number;
  _cansetstatusoncourier?: number;
  _cansetstatusdelivered?: number;

  _cansetstatusarchive?: number;
  _cansetstatuscanceled?: number;
  _cansetstatusreturned?: number;

  _cansetpaystatuspaid?: number;
  _cansetpaystatusneedreturn?: number;
  _cansetpaystatuscanceled?: number;
  _cansetpaystatusreturned?: number;

  constructor(
    id?: number | string,
    status?: number,
    paystatus?: number,
    paymethod?: number,
    date?: number,
    datedelivery?: number,
    datepayment?: number,
    pricewovat?: number,
    vattotal?: number,
    pricetotal?: number,
    discount?: number,
    realprice?: number,
    priceadvance?: number,
    pricedelivery?: number,
    pricepaid?: number,
    pricedue?: number,
    coments?: string,
    destinatar_idcountry?: number,
    destinatar_idlocation?: number,

    destinatar_address?: string,
    client_id?: number,
    client_name?: string,
    client_mobil?: string,
    client_email?: string,
    client_othercontacts?: string,
    client_type?: number,
    client_fct_fiz_name?: string,
    client_fct_fiz_idno?: string,
    client_fct_fiz_docnumber?: string,
    client_fct_fiz_address?: string,
    client_fct_fiz_phone?: string,
    client_fct_jur_company?: string,
    client_fct_jur_idno?: string,
    client_fct_jur_othercode?: string,
    client_fct_jur_address?: string,
    client_fct_jur_iban?: string,
    client_fct_jur_bank?: string,
    client_fct_jur_phone?: string,
    iddeliverymethod?: number,
    delivery_tracking?: string,
    delivery_tracking_url?: string,
    delivery_comments?: string
  ) {
    this.id = id || 0;
    this.status = status || Status.ACTIVE;
    this.paystatus = paystatus || 0;
    this.paymethod = paymethod || 0;
    this.date = date || 0;
    this.datedelivery = datedelivery || 0;
    this.datepayment = datepayment || 0;
    this.pricewovat = pricewovat || 0;
    this.vattotal = vattotal || 0;
    this.pricetotal = pricetotal || 0;
    this.discount = discount || 0;
    this.realprice = realprice || 0;
    this.priceadvance = priceadvance || 0;
    this.pricedelivery = pricedelivery || 0;
    this.pricepaid = pricepaid || 0;
    this.pricedue = pricedue || 0;
    this.coments = coments || "";
    this.destinatar_idcountry = destinatar_idcountry || 0;
    this.destinatar_idlocation = destinatar_idlocation || 0;

    this.destinatar_address = destinatar_address || "";
    this.client_id = client_id || 0;
    this.client_name = client_name || "";
    this.client_mobil = client_mobil || "";
    this.client_email = client_email || "";
    this.client_othercontacts = client_othercontacts || "";
    this.client_type = client_type || ClientType.INDIVIDUAL;
    this.client_fct_fiz_name = client_fct_fiz_name || "";
    this.client_fct_fiz_idno = client_fct_fiz_idno || "";
    this.client_fct_fiz_docnumber = client_fct_fiz_docnumber || "";
    this.client_fct_fiz_address = client_fct_fiz_address || "";
    this.client_fct_fiz_phone = client_fct_fiz_phone || "";
    this.client_fct_jur_company = client_fct_jur_company || "";
    this.client_fct_jur_idno = client_fct_jur_idno || "";
    this.client_fct_jur_othercode = client_fct_jur_othercode || "";
    this.client_fct_jur_address = client_fct_jur_address || "";
    this.client_fct_jur_iban = client_fct_jur_iban || "";
    this.client_fct_jur_bank = client_fct_jur_bank || "";
    this.client_fct_jur_phone = client_fct_jur_phone || "";
    this.iddeliverymethod = iddeliverymethod || 0;
    this.delivery_tracking = delivery_tracking || "";
    this.delivery_tracking_url = delivery_tracking_url || "";
    this.delivery_comments = delivery_comments || "";
  }
  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "StatusPayment",
        value: ["paystatus_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "PaymentMethod",
        value: ["paymethod_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Date",
        value: ["date_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "DateDelivery",
        value: ["datedelivery_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "DatePayment",
        value: ["datepayment_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "PriceWovat",
        value: ["pricewovat"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "VatTotal",
        value: ["vattotal"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "PriceTotal",
        value: ["pricetotal"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Discount",
        value: ["discount"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "RealPrice",
        value: ["realprice"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "PriceAdvance",
        value: ["priceadvance"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "PriceDelivery",
        value: ["pricedelivery"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "PricePaid",
        value: ["pricepaid"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "PriceDue",
        value: ["pricedue"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Coments",
        value: ["coments"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "destinatar_address",
        value: ["destinatar_address"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "deliveryMethodObj",
        value: ["deliveryMethodObj", "_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "DeliveryMethod",
        value: ["deliveryMethodObj", "_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Country",
        value: ["countryObj", "name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Location",
        value: ["locationObj", "_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "delivery_tracking",
        value: ["delivery_tracking"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "delivery_tracking_url",
        value: ["delivery_tracking_url"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "delivery_comments",
        value: ["delivery_comments"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string,
    callback?: () => void
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "id", "#"),
      OrderListColumns.columnClient(LL),
      OrderListColumns.columnStatus(LL),
      OrderListColumns.columnPayment(LL),
      OrderListColumns.columnDelivery(LL),
      OrderListColumns.columnDate(LL),
      OrderListColumns.columnComments(LL),
      OrderListColumns.columnAction(LL, callback),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete", "orderjournal", "orderproduct"],
        ["detail"],
        cbParent,
        specialType
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("order", true));
    obj.addFilter(t);

    t = new TbFilterDTO("paystatus", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("order_payment", true));
    obj.addFilter(t);

    return obj;
  }

  static parseToSelectOptions(data: Array<OrderDto>): Array<SelectOptions> {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    data.forEach((element) => {
      result.push(
        new SelectOptions(
          CommonTools.processObjectField(element, ["id"]),
          CommonTools.processObjectField(element, ["identifier"])
        )
      );
    });
    return result;
  }

  static toEditDto(order: OrderDto): PutOrderDto {
    return new PutOrderDto(order.coments, order.delivery_comments);
  }
}

export class SetActionDto implements Idto {
  uuid: string;
  constructor(uuid: string) {
    this.uuid = uuid;
  }
}

export class PutOrderDto implements Idto {
  coments?: string;
  delivery_comments?: string;

  constructor(coments?: string, delivery_comments?: string) {
    this.coments = coments || "";
    this.delivery_comments = delivery_comments || "";
  }
}
