import { Box, Skeleton } from "@mui/material";
import { ButtonWithLoading } from "components/elements/button/ButtonWithLoading";
import { SelectFilter } from "components/product/filter/SelectFilter";
import { SelectFilterDictionar } from "components/product/filterdictionar/SelectFilterDictionar";
import { OfferFilterDto } from "dto/product/offerfilter.dto";
import { useForm } from "hooks/useForm";
import { useLabel } from "hooks/useLabel";
import React from "react";


import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObject: OfferFilterDto;
  onSubmit: (data: OfferFilterDto) => void;
  loading: boolean;
};
const FormOfferFilter: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
}) => {
  const { LL } = useLabel();

  const [obj, disabled, setObjField, , , setObject] = useForm<OfferFilterDto>(
    defaultObject,
    RequiredValidator.getValidators(["idfilter", "idfilterdictionar"])
  );

  const setFilter = (field: string, value: any) => {
    let t = JSON.parse(JSON.stringify(obj));
    t[field] = value;
    t["idfilterdictionar"] = "";
    setObject(t);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <SelectFilter setObjectField={setFilter} value={obj.idfilter} />
        </Box>
        <Box mt={3}>
          {obj.idfilter ? (
            <SelectFilterDictionar
              setObjectField={setObjField}
              value={obj.idfilterdictionar}
              idFilter={obj.idfilter}
              all
            />
          ) : (
            <Skeleton />
          )}
        </Box>

        <Box mt={3}>
          <ButtonWithLoading
            type="submit"
            disabled={loading || disabled}
            loading={loading}
          >
            {LL("btn_submit")}
          </ButtonWithLoading>
        </Box>
      </form>
    </Box>
  );
};

export { FormOfferFilter };
