import { Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";
import { useLabel } from "hooks/useLabel";

import {  Comment } from "@mui/icons-material";
import ReviewsIcon from "@mui/icons-material/Reviews";
import { OrderDto } from "dto/sale/order.dto";


type Props = {
    object: OrderDto;
  };

const ColumnComments: React.FC<Props> = ({ object }) => {
  const { LL } = useLabel();
  if (!object) return <></>;
  return (
    <Stack
              direction={"column"}
              alignItems={"flex-start"}
              justifyContent={"center"}
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              <Stack
                direction={"row"}
                spacing={2}
                alignItems={"center"}
                justifyContent={"flex-start"}
              >
                <Tooltip title={LL("comments")} placement="right">
                  <Comment />
                </Tooltip>
                <Typography>
                  {CommonTools.processObjectField(object, [ "coments"])}
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                spacing={2}
                alignItems={"center"}
                justifyContent={"flex-start"}
              >
                <Tooltip title={LL("delivery_comments")} placement="right">
                  <ReviewsIcon />
                </Tooltip>
                <Typography>
                  {CommonTools.processObjectField(object, [
                    
                    "delivery_comments",
                  ])}
                </Typography>
              </Stack>
            </Stack>
  );
};

export { ColumnComments };
